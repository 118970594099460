@import "../base/_base.scss";
@import "../components/_page-title.scss";

$layout-breakpoint-small: 768px;
$layout-breakpoint-medium: 1330px;

$layout-breakpoint-height: 700px;

.side-nav-tooltip {
  display: none;
}

.tn-webclient {
  height: 100vh;
  overflow-y: auto;

  display: flex;
  flex-direction: column;

  .tn-header {
    height: $layout-header-height;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav-logo-wrapper {
      display: flex;
      align-items: center;
    }
  }

  .tn-content-body {
    height: $layout-content-body-height;
    display: flex;

    .tn-sidebar {
      min-width: $layout-normal-sidebar-min-width;
      width: $layout-normal-sidebar-width;
      
      border-right: 1px solid $border-color;
      overflow-y: auto;
    }

    .tn-content-container {
      width: $layout-normal-content-full-width;
      overflow-y: hidden;

      .tn-content {
        height: 100%;
        display: flex;
      }

      .tn-content-full {
        width: 100%;
        overflow-y: hidden;
      }

      .tn-content-left {
        width: $layout-left-content-normal-width;
        min-width: $layout-left-content-min-width;
        overflow-y: hidden;

        border-right: 1px solid $border-color;
      }

      .tn-content-right {
        width: $layout-right-content-normal-width;
        overflow-y: hidden;
      }
    }
  } // tn-content-wrapper

  .tn-footer {
    height: $layout-footer-height;
  }
}

.build-label, .system-label, .site-label, .topnav-label {
  font-size: x-large;
  margin: 0 10px;

  &.red {
    color: red;
  }
}

/* Medium Size */
@media only screen and (max-width: $layout-breakpoint-medium) {
  .side-nav-tooltip {
    display: block;
  }

  .tn-sidebar {
    min-width: $layout-collapsed-sidebar-fixed-width !important;
    width: $layout-collapsed-sidebar-fixed-width !important;

    .label-icon {
      height: 20px;
    }

    .show-label-icon {
      background-image: url('../../../assets/images/side_nav/default_nav_icon.png');
      background-size: cover;
      height: 20px;
      width: 20px;
    }

    .label-title {
      display: none !important;
    }
    .nav-item-wrapper {
      display: flex !important;
      justify-content: center !important;
      .nav-item {
        position: relative !important;

        .nav-count {
          position: absolute;
          height: 15px !important;
          width: 15px !important;
          font-size: 9px;
          left: 13px;
          top: 13px;
        }
      }

      .nav-separator {
        width: 100%;
        margin: unset;
        height: 1px;
        border: none;
      }
    }
  }

  .tn-content-container {
    width: $layout-collapsed-content-full-width !important;
  }
}

/* Small Size */
@media only screen and (max-width: $layout-breakpoint-small) {
  .current-focus-content {
    width: 100% !important;
    min-width: 100% !important;
    border: none !important;
  }
  
  .current-unfocus-content {
    display: none !important;
  }

  .chat-room-header, .chat-search-header {
    .page-title-wrapper {
      height: calc(#{$page-title-height} + 20px) !important;
    }
  }
}

/* Shorter Screen */
@media only screen and (max-height: $layout-breakpoint-height) {
  .chat-room-header, .chat-search-header {
    .page-title-wrapper {
      height: calc(#{$page-title-height} + 20px) !important;
    }
  }
}