/* TeamNote Theme */

$tn-theme-color-primary: #082c4e;
$tn-text-color-primary: #2978cc;
$tn-text-color-secondary: #666666;

$tn-base-bg-image-url: url("../../../assets/images/general/login_bg.jpg");
$tn-base-bg-color: #082c4e;

$tn-login-logo-image-url: url("../../../assets/images/general/login_logo.png");
$tn-login-logo-height: 160px;
$tn-login-logo-width: 183px;
$tn-login-page-header-bg: transparent;
$tn-login-page-header-align-items: flex-end;

$tn-login-page-body-bg: transparent;

$tn-nav-logo-image-url: url("../../../assets/images/general/nav_logo.png");
$tn-nav-logo-height: 50px;
$tn-nav-logo-width: 189px;

$tn-empty-page-logo-image-url: url("../../../assets/images/general/empty_page_logo.png");
$tn-empty-page-logo-width: 160px;
$tn-empty-page-logo-height: 160px;

$tn-unread-bubble-bg: #00d189;
$tn-pending-bubble-bg: #ff8000;
$tn-unread-bubble-text: #ffffff;

$tn-chatroom-bg: url("../../../assets/images/chatroom/chatroom_bg.png");
$tn-chatroom-msg-bg-left: #ffffff;
$tn-chatroom-msg-bubble-left-image-url: url("../../../assets/images/chatroom/chatroom_text_bubble_tip_left.png");
$tn-chatroom-msg-audio-play-left-image-url: url("../../../assets/images/chatroom/chatroom_play_left.png");
$tn-chatroom-msg-audio-pause-left-image-url: url("../../../assets/images/chatroom/chatroom_pause_left.png");
$tn-chatroom-msg-audio-voice-default-left-image-url: url("../../../assets/images/chatroom/chatroom_voice_default_left.png");
$tn-chatroom-msg-audio-voice-play1-left-image-url: url("../../../assets/images/chatroom/chatroom_voice_play1_left.png");
$tn-chatroom-msg-audio-voice-play2-left-image-url: url("../../../assets/images/chatroom/chatroom_voice_play2_left.png");
$tn-chatroom-msg-audio-voice-play3-left-image-url: url("../../../assets/images/chatroom/chatroom_voice_play3_left.png");

$tn-chatroom-msg-bg-right: #207ac6;
$tn-chatroom-msg-bubble-right-image-url: url("../../../assets/images/chatroom/chatroom_text_bubble_tip_right.png");
$tn-chatroom-msg-audio-play-right-image-url: url("../../../assets/images/chatroom/chatroom_play_right.png");
$tn-chatroom-msg-audio-pause-right-image-url: url("../../../assets/images/chatroom/chatroom_pause_right.png");
$tn-chatroom-msg-audio-voice-default-right-image-url: url("../../../assets/images/chatroom/chatroom_voice_default_right.png");
$tn-chatroom-msg-audio-voice-play1-right-image-url: url("../../../assets/images/chatroom/chatroom_voice_play1_right.png");
$tn-chatroom-msg-audio-voice-play2-right-image-url: url("../../../assets/images/chatroom/chatroom_voice_play2_right.png");
$tn-chatroom-msg-audio-voice-play3-right-image-url: url("../../../assets/images/chatroom/chatroom_voice_play3_right.png");

$tn-vote-result-bar: #00a1ff;
$tn-news-avatar-image-url: url("../../../assets/images/general/news-icon.png");
$tn-vote-avatar-image-url: url("../../../assets/images/general/voting-icon.png");

$tn-button-primary-bg: #296DB0;

// Corporate materials
$tn-corporate-material-name-color: $tn-text-color-primary;
$tn-corporate-material-folder-image-url: url("../../../assets/images/corporate_material/corporate_material_folder.png");
$tn-corporate-material-img-image-url: url("../../../assets/images/corporate_material/corporate_material_img.png");
$tn-corporate-material-pdf-image-url: url("../../../assets/images/corporate_material/corporate_material_pdf.png");
$tn-corporate-material-video-image-url: url("../../../assets/images/corporate_material/corporate_material_video.png");
$tn-corporate-material-audio-image-url: url("../../../assets/images/corporate_material/corporate_material_audio.png");
$tn-corporate-material-doc-image-url: url("../../../assets/images/corporate_material/corporate_material_doc.png");
$tn-corporate-material-url-image-url: url("../../../assets/images/corporate_material/corporate_material_url.png");
$tn-corporate-material-ebook-image-url: url("../../../assets/images/corporate_material/corporate_material_ebook.png");
$tn-corporate-material-ebook-font-size-image-url: url("../../../assets/images/corporate_material/corporate_material_ebook_font_size.png");
$tn-corporate-material-ebook-play-image-url: url("../../../assets/images/corporate_material/corporate_material_ebook_play.png");
$tn-corporate-material-ebook-pause-image-url: url("../../../assets/images/corporate_material/corporate_material_ebook_pause.png");
$tn-corporate-material-ebook-prev-page-image-url: url("../../../assets/images/corporate_material/corporate_material_ebook_previous.png");
$tn-corporate-material-ebook-next-page-image-url: url("../../../assets/images/corporate_material/corporate_material_ebook_next.png");

$tn-corporate-material-view-mode-list-image-url: url("../../../assets/images/corporate_material/corporate_material_toolbar_list.png");
$tn-corporate-material-view-mode-grid-image-url: url("../../../assets/images/corporate_material/corporate_material_toolbar_grid.png");

$tn-button-close-image-url: url("../../../assets/images/general/btn_close.png");
$tn-store-report-submit-button-image-url: url("../../../assets/images/store_report/submit.png");


$tn-form-submit-button-image-url: url("../../../assets/images/form/form_submit_icon.png");
$tn-form-attachment-photo-icon-image-url: url("../../../assets/images/form/form_attachment_photo_icon.png");
$tn-form-attachment-video-icon-image-url: url("../../../assets/images/form/form_attachment_video_icon.png");
$tn-form-attachment-file-icon-image-url: url("../../../assets/images/form/form_attachment_file_icon.png");
$tn-form-delete-icon-image-url: url("../../../assets/images/form/form_delete_icon.png");


$teamnote-theme: (
  "theme-color-primary": $tn-theme-color-primary,
  "text-color-primary": $tn-text-color-primary,
  "text-color-secondary": $tn-text-color-secondary,
  "border-color-primary": $tn-theme-color-primary,

  // login page
  "base-bg-image-url": $tn-base-bg-image-url,
  "base-bg-color": $tn-base-bg-color,
  "login-logo-image-url": $tn-login-logo-image-url,
  "login-logo-height": $tn-login-logo-height,
  "login-logo-width": $tn-login-logo-width,
  "login-page-header-bg": $tn-login-page-header-bg,
  "login-page-header-align-items": $tn-login-page-header-align-items,

  "login-page-body-bg": $tn-login-page-body-bg,

  // nav logo
  "nav-logo-image-url": $tn-nav-logo-image-url,
  "nav-logo-height": $tn-nav-logo-height,
  "nav-logo-width": $tn-nav-logo-width,

  // empty page logo
  "empty-page-logo-image-url": $tn-empty-page-logo-image-url,
  "empty-page-logo-height": $tn-empty-page-logo-height,
  "empty-page-logo-width": $tn-empty-page-logo-width,

  // unread bubble
  "unread-bubble-bg": $tn-unread-bubble-bg,
  "unread-pending-bg": $tn-pending-bubble-bg,
  "unread-bubble-text": $tn-unread-bubble-text,
  
  // chatroom
  "chatroom-bg": $tn-chatroom-bg,
  "chatroom-msg-bg-left": $tn-chatroom-msg-bg-left,
  "chatroom-msg-bubble-left-image-url": $tn-chatroom-msg-bubble-left-image-url,

  "chatroom-msg-bg-right": $tn-chatroom-msg-bg-right,
  "chatroom-msg-bubble-right-image-url": $tn-chatroom-msg-bubble-right-image-url,

  // chatroom voice audio
  "chatroom-msg-audio-play-left-image-url": $tn-chatroom-msg-audio-play-left-image-url,
  "chatroom-msg-audio-pause-left-image-url": $tn-chatroom-msg-audio-pause-left-image-url,
  "chatroom-msg-audio-voice-default-left-image-url": $tn-chatroom-msg-audio-voice-default-left-image-url,
  "chatroom-msg-audio-voice-play1-left-image-url": $tn-chatroom-msg-audio-voice-play1-left-image-url,
  "chatroom-msg-audio-voice-play2-left-image-url": $tn-chatroom-msg-audio-voice-play2-left-image-url,
  "chatroom-msg-audio-voice-play3-left-image-url": $tn-chatroom-msg-audio-voice-play3-left-image-url,

  "chatroom-msg-audio-play-right-image-url": $tn-chatroom-msg-audio-play-left-image-url,
  "chatroom-msg-audio-pause-right-image-url": $tn-chatroom-msg-audio-pause-left-image-url,
  "chatroom-msg-audio-voice-default-right-image-url": $tn-chatroom-msg-audio-voice-default-left-image-url,
  "chatroom-msg-audio-voice-play1-right-image-url": $tn-chatroom-msg-audio-voice-play1-left-image-url,
  "chatroom-msg-audio-voice-play2-right-image-url": $tn-chatroom-msg-audio-voice-play2-left-image-url,
  "chatroom-msg-audio-voice-play3-right-image-url": $tn-chatroom-msg-audio-voice-play3-left-image-url,

  // news
  "vote-result-bar": $tn-vote-result-bar,
  "news-avatar-image-url": $tn-news-avatar-image-url,
  "vote-avatar-image-url": $tn-vote-avatar-image-url,

  // buttons
  "button-primary-bg": $tn-button-primary-bg,
  "button-close-bg": $tn-button-close-image-url,

  // Corporate Materials
  "corporate-material-name-color": $tn-corporate-material-name-color,
  "corporate-material-folder-image-url": $tn-corporate-material-folder-image-url,
  "corporate-material-doc-image-url": $tn-corporate-material-doc-image-url,
  "corporate-material-img-image-url": $tn-corporate-material-img-image-url,
  "corporate-material-video-image-url": $tn-corporate-material-video-image-url,
  "corporate-material-audio-image-url": $tn-corporate-material-audio-image-url,
  "corporate-material-pdf-image-url": $tn-corporate-material-pdf-image-url,
  "corporate-material-url-image-url": $tn-corporate-material-url-image-url,
  "corporate-material-ebook-image-url": $tn-corporate-material-ebook-image-url,
  "corporate-material-view-model-list-image-url": $tn-corporate-material-view-mode-list-image-url,
  "corporate-material-view-model-grid-image-url": $tn-corporate-material-view-mode-grid-image-url,

  // Store report
  "store-report-submit-button-image-url": $tn-store-report-submit-button-image-url,

  // Form
  "form-submit-button-image-url": $tn-form-submit-button-image-url,
  "form-attachment-photo-icon-image-url": $tn-form-attachment-photo-icon-image-url,
  "form-attachment-video-icon-image-url": $tn-form-attachment-video-icon-image-url,
  "form-attachment-file-icon-image-url": $tn-form-attachment-file-icon-image-url,
  "form-delete-icon-image-url": $tn-form-delete-icon-image-url
);

