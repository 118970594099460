@import './_base.scss';
@import '../modules/_chat.scss';

@mixin background-image-by-src($image-url) {
  background-image: url($image-url);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; /* Generic*/
}

@mixin background-image-with-src() {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; /* Generic*/
}

@mixin item-highlight() {
  background-color: rgba(0, 0, 0, 0.075);
}

@mixin flex-vertical-center() {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin general-listing-cell() {
  min-height: 80px;
  padding: 10px;
  border-bottom: 1px solid $border-color;

  display: flex;

  &:hover {
    @include item-highlight();

    ::ng-deep .hover-dropdown {
      display: flex;
    }
  }
}

@mixin text-overflow-wrapping() {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin clean-button() {
  width: 40px;
  height: 40px;
  background-color: transparent;
  color: white;
  box-shadow: none;
  border: none;
  border-radius: 25%;
  outline: none;
  padding: 0 5px;
  margin: 0 5px;

  i {
    transform: scale(1.5);
  }

  &:hover, &:active, &:focus {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

@mixin minimalistic-input-box($color) {  
  color: $color;
  
  border: 0;
  border-bottom: 1px solid $color;
  border-radius: unset;

  background-color: transparent;
  box-shadow: none;
}

@mixin important-user() {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  // transition: all .3s;

  &.global-vip {
    color: $chatroom-global-vip-text !important;

    .global-vip-icon {
      width: 16px;
      height: 16px;
      @include background-image-by-src($chatroom-global-vip-icon);
    }
  }
    
  &.individual-vip {
    color: $chatroom-individual-vip-text !important;

    .individual-vip-icon {
      width: 16px;
      height: 16px;
      // animation: fadein .3s forwards;
      @include background-image-by-src($chatroom-individual-vip-icon);
    }
  }
}
