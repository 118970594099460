/* Import all theme definition files */

// Base theme
@import "./teamnote/_teamnote.scss";

// Client theme
@import "./test/_test.scss";
@import "./fwd/_fwd.scss";
@import "./act/_act.scss";
@import "./boc/_boc.scss";
@import "./hkaa/_hkaa.scss";
@import "./scl/_scl.scss";
@import "./amcm/_amcm.scss";
@import "./hkwtia/_hkwtia.scss";
@import "./dse/_dse.scss";
@import "./fsd/_fsd.scss";
@import "./isa/_isa.scss";
@import "./mtr/_mtr.scss";
@import "./avseco/_avseco.scss";
@import "./oids/_oids.scss";
@import "./archsd/_archsd.scss";
@import "./emsd/_emsd.scss";

/* Define $all-themes with each theme's override value */
$all-themes: (
  "shan": $test-override,
  "fwd": $fwd-override,
  "act": $act-override,
  "boc": $boc-override,
  "hkaa": $hkaa-override,
  "scl": $scl-override,
  "amcm": $amcm-override,
  "hkwtia": $hkwtia-override,
  "dse": $dse-override,
  "fsd": $fsd-override,
  "isa": $isa-override,
  "mtr": $mtr-override,
  "avseco": $avseco-override,
  "avsecoProd": $avseco-override,
  "oids": $oids-override,
  "archsd": $archsd-override,
  "emsd": $emsd-override
);
