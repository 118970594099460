@import "../base/_mixins.scss";
@import "../components/_list.scss";
@import "../components/_avatar.scss";
@import "../themes/_themes.scss";

/* Corporate Material */

$corporate-material-list-height: $page-title-search-bar-list-height;

$corporate-material-icon-size: $avatar-normal-size;

.corporate-material-list {
  .name {
    color: $tn-corporate-material-name-color;
  }

  .folder {
    background-image: $tn-corporate-material-folder-image-url;
  }
  .doc {
    background-image: $tn-corporate-material-doc-image-url;
  }
  .txt {
    background-image: $tn-corporate-material-doc-image-url;
  }
  .img {
    background-image: $tn-corporate-material-img-image-url;
  }
  .video {
    background-image: $tn-corporate-material-video-image-url;
  }
  .audio {
    background-image: $tn-corporate-material-audio-image-url;
  }
  .pdf {
    background-image: $tn-corporate-material-pdf-image-url;
  }
  .url {
    background-image: $tn-corporate-material-url-image-url;
  }
  .ebook, .ebook_page {
    background-image: $tn-corporate-material-ebook-image-url;
  }
}

// Ebook
$tn-corporate-material-ebook-content-wrapper-height: $page-title-list-height;
$tn-corporate-material-ebook-footer-height: 50px;
$tn-corporate-material-ebook-content-height: calc(100% - #{$tn-corporate-material-ebook-footer-height});

$tn-corporate-material-ebook-basic-button-size: 30px;
$tn-corporate-material-ebook-page-controls-size: 20px;

.corporate-material {

  .button {
    @include background-image-with-src();
    background-size: contain;
    width: $tn-corporate-material-ebook-basic-button-size;
    height: $tn-corporate-material-ebook-basic-button-size;

    &.button-hidden {
      background-image: none;
      cursor: default;
    }
  }
  .font-size-button {
    background-image: $tn-corporate-material-ebook-font-size-image-url;
  }

  .play-button {
    background-image: $tn-corporate-material-ebook-play-image-url;
  }

  .pause-button {
    background-image: $tn-corporate-material-ebook-pause-image-url;
  }

  .prev-page-button {
    width: $tn-corporate-material-ebook-page-controls-size;
    height: $tn-corporate-material-ebook-page-controls-size;
    background-image: $tn-corporate-material-ebook-prev-page-image-url;
  }

  .next-page-button {
    width: $tn-corporate-material-ebook-page-controls-size;
    height: $tn-corporate-material-ebook-page-controls-size;
    background-image: $tn-corporate-material-ebook-next-page-image-url;
  }

  .view-mode-button-wrapper {
    .corporate-material-view-mode-icon {
      @include background-image-with-src();
      width: 40px;
      height: 40px;
      background-size: contain;

      &.list-icon {
        background-image: $tn-corporate-material-view-mode-list-image-url;
      }

      &.grid-icon {
        background-image: $tn-corporate-material-view-mode-grid-image-url;
      }
    }
  }
}