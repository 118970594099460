// EMSD Styles
// Created Date: 2024-08-23
@import "../../scss/base/base";
@import "../../scss/layouts/layout";

$emsd-override: (
  // "theme-color-primary": #082c4e,
  // "text-color-primary": #2978cc,

  // Login page
  "base-bg-image-url": url("../../../assets/images/clients/emsd/login_bg.png"),
  "base-bg-color": #082c4e,

  "login-logo-image-url": url("../../../assets/images/clients/emsd/login_logo.png"),
  "login-logo-height": 192px,
  "login-logo-width": 200px,

  // nav logo
  "nav-logo-image-url": url("../../../assets/images/clients/emsd/nav_logo.png"),
  "nav-logo-height": 53px,
  "nav-logo-width": 60px,

  // // empty page logo
  // "empty-page-logo-image-url": url("../../../assets/images/clients/emsd/empty_page_logo.png"),
  // "empty-page-logo-height": 48px,
  // "empty-page-logo-width": 160px,

  // // chatroom
  "chatroom-bg": none,
  "chatroom-msg-bg-left": #f1f1f1,
  "chatroom-msg-bg-right": #207ac6,
  "chatroom-msg-select-box": #207ac6,
  "chatroom-msg-sender-name-color": #7b7a7a,
  "chatroom-msg-read-color": #7b7a7a,
  "chatroom-msg-time-color": #7b7a7a,

  // "chatroom-msg-bg-right": #bd8a43,
  // "chatroom-msg-bubble-right-image-url": url("../../../assets/images/clients/emsd/chatroom_text_bubble_tip_right.png"),

  // // buttons
  // "button-primary-bg": #bc8a43,

  // "unread-bubble-bg": #5bb170,

  // // Corporate material
  // "corporate-material-name-color": #292929
);

.theme-emsd {
  .tn-sidebar {
    min-width: $layout-fixed-sidebar-width !important;
    width: $layout-fixed-sidebar-width !important;
  }

  .tn-content-container {
    width: 100% !important;
  }

  @media only screen and (max-width: $layout-breakpoint-medium) {
    .tn-sidebar {
      min-width: $layout-collapsed-sidebar-fixed-width !important;
      width: $layout-collapsed-sidebar-fixed-width !important;
    }
  
    .tn-content-container {
      width: $layout-collapsed-content-full-width !important;
    }
  }
}