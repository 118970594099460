// Created by Shan - 2019-06-19

$avseco-override: (
  // login page
  "login-logo-image-url": url("../../../assets/images/clients/avseco/login_logo.png"),
  "login-logo-height": 100px,
  "login-logo-width": 160px,

  // nav logo
  "nav-logo-image-url": url("../../../assets/images/clients/avseco/nav_logo.png"),
  "nav-logo-height": 50px,
  "nav-logo-width": 71px,

  // empty page logo
  "empty-page-logo-image-url": url("../../../assets/images/clients/avseco/empty_page_logo.png"),
  "empty-page-logo-height": 118px,
  "empty-page-logo-width": 200px
);

.theme-avseco {
  .build-label {
    color: red;
  }
}