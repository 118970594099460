/* Import all theme definition files */
@import "../../clients/_clients.scss";

@import "../components/_button.scss";
@import "../components/_link.scss";

// Define base CSS classes (to be override by theme if applicable)
body {
  background-color: $tn-base-bg-color;
}
.base-bg {
  background-color: $tn-base-bg-color;
  background-image: $tn-base-bg-image-url;
}
.theme-color-primary {
  background-color: $tn-theme-color-primary;
}
.text-color-primary {
  color: $tn-text-color-primary;
}
.border-color-primary {
  border-color: $tn-theme-color-primary;
}
.text-color-secondary {
  color: $tn-text-color-secondary;
}
.login-logo {
  background-image: $tn-login-logo-image-url;
  height: $tn-login-logo-height;
  width: $tn-login-logo-width;
}
.login-page-header {
  background-color: $tn-login-page-header-bg;
  min-height: $tn-login-logo-height;
  align-items: $tn-login-page-header-align-items;
}
.login-page-body {
  background-color: $tn-login-page-body-bg;
}
.nav-logo {
  background-image: $tn-nav-logo-image-url;
  height: $tn-nav-logo-height;
  width: $tn-nav-logo-width;
}
.empty-page-logo {
  background-image: $tn-empty-page-logo-image-url;
  height: $tn-empty-page-logo-height;
  width: $tn-empty-page-logo-width;
}
.chatroom-bg {
  background-image: $tn-chatroom-bg;
}
.chatroom-main-color {
  color: $tn-chatroom-msg-bg-right;
}
.message-body {
  .message-bubble-tip {
    background-image: $tn-chatroom-msg-bubble-left-image-url;
  }
  .message-content {
    background-color: $tn-chatroom-msg-bg-left;
  }
  .horizontal-hover {
    .hovering-menu {
      background: linear-gradient(to right, transparent 0%, $tn-chatroom-msg-bg-left 50%);
    }
  }
  .audio-play {
    background-image: $tn-chatroom-msg-audio-play-left-image-url;
  }
  .audio-pause {
    background-image: $tn-chatroom-msg-audio-pause-left-image-url;
  }
  .audio-voice-default {
    background-image: $tn-chatroom-msg-audio-voice-default-left-image-url;
  }
  .audio-voice-play1 {
    background-image: $tn-chatroom-msg-audio-voice-play1-left-image-url;
  }
  .audio-voice-play2 {
    background-image: $tn-chatroom-msg-audio-voice-play2-left-image-url;
  }
  .audio-voice-play3 {
    background-image: $tn-chatroom-msg-audio-voice-play3-left-image-url;
  }
}
.message-by-me {
  .message-bubble-tip {
    background-image: $tn-chatroom-msg-bubble-right-image-url;
  }
  .message-content {
    background-color: $tn-chatroom-msg-bg-right;
  }
  .horizontal-hover {
    .hovering-menu {
      background: linear-gradient(to right, transparent 0%, $tn-chatroom-msg-bg-right 50%);
    }
  }
  .audio-play {
    background-image: $tn-chatroom-msg-audio-play-right-image-url;
  }
  .audio-pause {
    background-image: $tn-chatroom-msg-audio-pause-right-image-url;
  }
  .audio-voice-default {
    background-image: $tn-chatroom-msg-audio-voice-default-right-image-url;
  }
  .audio-voice-play1 {
    background-image: $tn-chatroom-msg-audio-voice-play1-right-image-url;
  }
  .audio-voice-play2 {
    background-image: $tn-chatroom-msg-audio-voice-play2-right-image-url;
  }
  .audio-voice-play3 {
    background-image: $tn-chatroom-msg-audio-voice-play3-right-image-url;
  }
}
.vote-result-bar {
  background-color: $tn-vote-result-bar
}
.avatar-news {
  background-image: $tn-news-avatar-image-url;
}
.avatar-vote {
  background-image: $tn-vote-avatar-image-url;
}
.unread-bubble {
  background-color: $tn-unread-bubble-bg;
  color: $tn-unread-bubble-text;
}
.pending-bubble {
  background-color: $tn-pending-bubble-bg;
  color: $tn-unread-bubble-text;
}

.tn-button-primary {
  @include tn-button-variant($tn-button-primary-bg);
}

a {
  @include tn-link-variant($tn-text-color-primary);
}

.btn-close {
  background-image: $tn-button-close-image-url;
}

.report-submit-button {
  background-image: $tn-store-report-submit-button-image-url;
}

.form-submit-button {
  background-image: $tn-form-submit-button-image-url;
}
.form-attachment-photo-icon {
  background-image: $tn-form-attachment-photo-icon-image-url;
}
.form-attachment-video-icon {
  background-image: $tn-form-attachment-video-icon-image-url;
}
.form-attachment-file-icon {
  background-image: $tn-form-attachment-file-icon-image-url;
}
.form-delete-icon {
  background-image: $tn-form-delete-icon-image-url;
}

$theme-map: null;
/* Functions to define all themed classes */
@mixin themify ($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

/* Returns values by key */
@function themed($key) {
  @return map-get($theme-map, $key);
}

// Generate all theme styles
@include themify($all-themes) {

  & {
    background-color: themed('base-bg-color');
  }

  .base-bg {
    background-color: themed('base-bg-color');
    background-image: themed('base-bg-image-url');
  }

  .theme-color-primary {
    background-color: themed('theme-color-primary');
  }

  .text-color-primary {
    color: themed('text-color-primary');
  }

  .border-color-primary {
    border-color: themed('border-color-primary');
  }

  .text-color-secondary {
    color: themed('text-color-secondary');
  }

  .page-title-wrapper {
    .page-title {
      border-bottom: solid 2px themed('button-primary-bg');
    }
  }

  .login-logo {
    background-image: themed('login-logo-image-url');
    height: themed('login-logo-height');
    width: themed('login-logo-width');
  }
  .login-page-header {
    background-color: themed('login-page-header-bg');
    min-height: themed('login-logo-height');
    align-items: themed('login-page-header-align-items');
  }
  .login-page-body {
    background-color: themed('login-page-body-bg');
  }

  .nav-logo {
    background-image: themed('nav-logo-image-url');
    height: themed('nav-logo-height');
    width: themed('nav-logo-width');
  }

  .empty-page-logo {
    background-image: themed('empty-page-logo-image-url');
    height: themed('empty-page-logo-height');
    width: themed('empty-page-logo-width');
  }

  .chatroom-bg {
    background-image: themed('chatroom-bg');
  }

  .chatroom-bg-color {
    background-color: themed('chatroom-bg-color');
  }

  .empty {
    color: themed('chatroom-msg-select-box') !important;
  }

  .chatroom-main-color {
    color: themed('chatroom-msg-bg-right');
  }

  .message-body {
    .sender-name {
      color: themed('chatroom-msg-sender-name-color') !important;
    }

    .message-bubble-tip {
      background-image: themed('chatroom-msg-bubble-left-image-url');
    }
    .message-content {
      background-color: themed('chatroom-msg-bg-left');
    }

    .message-info {
      .read-by-count {
        color: themed('chatroom-msg-read-color') !important;
      }

      .message-time {
        color: themed('chatroom-msg-time-color') !important;
      }

      .send-loading {
        .letter {
          color: themed('chatroom-msg-select-box') !important;
        }
      }

      .zh-send-loading {
        .zh-letter {
          color: themed('chatroom-msg-select-box') !important;
        }
      }
    }

    .message-edit-container {
      color: themed('chatroom-msg-read-color') !important;
    }

    .horizontal-hover {
      .hovering-menu {
        background: linear-gradient(to right, transparent 0%, themed('chatroom-msg-bg-left') 50%);
      }
    }
    .audio-play {
      background-image: themed('chatroom-msg-audio-play-left-image-url');
    }
    .audio-pause {
      background-image: themed('chatroom-msg-audio-pause-left-image-url');
    }
    .audio-voice-default {
      background-image: themed('chatroom-msg-audio-voice-default-left-image-url');
    }
    .audio-voice-play1 {
      background-image: themed('chatroom-msg-audio-voice-play1-left-image-url');
    }
    .audio-voice-play2 {
      background-image: themed('chatroom-msg-audio-voice-play2-left-image-url');
    }
    .audio-voice-play3 {
      background-image: themed('chatroom-msg-audio-voice-play3-left-image-url');
    }
  }
  .message-by-me {
    .message-bubble-tip {
      background-image: themed('chatroom-msg-bubble-right-image-url');
    }
    .message-content {
      background-color: themed('chatroom-msg-bg-right');
    }

    .horizontal-hover {
      .hovering-menu {
        background: linear-gradient(to right, transparent 0%, themed('chatroom-msg-bg-right') 50%);
      }
    }
    .audio-play {
      background-image: themed('chatroom-msg-audio-play-right-image-url');
    }
    .audio-pause {
      background-image: themed('chatroom-msg-audio-pause-right-image-url');
    }
    .audio-voice-default {
      background-image: themed('chatroom-msg-audio-voice-default-right-image-url');
    }
    .audio-voice-play1 {
      background-image: themed('chatroom-msg-audio-voice-play1-right-image-url');
    }
    .audio-voice-play2 {
      background-image: themed('chatroom-msg-audio-voice-play2-right-image-url');
    }
    .audio-voice-play3 {
      background-image: themed('chatroom-msg-audio-voice-play3-right-image-url');
    }
  }

  .vote-result-bar {
    background-color: themed('vote-result-bar');
  }
  .avatar-news {
    background-image: themed('news-avatar-image-url');
  }
  .avatar-vote {
    background-image: themed('vote-avatar-image-url');
  }

  .unread-bubble {
    background-color: themed('unread-bubble-bg');
    color: themed('unread-bubble-text');
  }

  .pending-bubble {
    background-color: themed('pending-bubble-bg');
    color: themed('unread-bubble-text');
  }

  .tn-button-primary {
    @include tn-button-variant(themed("button-primary-bg"));
  }

  a {
    @include tn-link-variant(themed("text-color-primary"));
  }

  .btn-close {
    background-image: themed('button-close-bg');
  }

  // Corporate Material
  .corporate-material-list {
    .name {
      color: themed("corporate-material-name-color");
    }
    .folder {
      background-image: themed("corporate-material-folder-image-url");
    }
    .doc {
      background-image: themed("corporate-material-doc-image-url");
    }
    .img {
      background-image: themed("corporate-material-img-image-url");
    }
    .video {
      background-image: themed("corporate-material-video-image-url");
    }
    .pdf {
      background-image: themed("corporate-material-pdf-image-url");
    }
    .url {
      background-image: themed("corporate-material-url-image-url");
    }
    .ebook, .ebook_page {
      background-image: themed("corporate-material-ebook-image-url");
    }
  }

  .view-mode-button-wrapper {
    .corporate-material-view-mode-icon {

      &.list-icon {
        background-image: themed("corporate-material-view-model-list-image-url");
      }

      &.grid-icon {
        background-image: themed("corporate-material-view-model-grid-image-url");
      }
    }
  }

  // Store report
  .report-submit-button {
    background-image: themed("store-report-submit-button-image-url");
  }

  // Form
  .form-submit-button {
    background-image: themed("form-submit-button-image-url");
  }
  .form-attachment-photo-icon {
    background-image: themed("form-attachment-photo-icon-image-url");
  }
  .form-attachment-video-icon {
    background-image: themed("form-attachment-video-icon-image-url");
  }
  .form-attachment-file-icon {
    background-image: themed("form-attachment-file-icon-image-url");
  }
  .form-delete-icon {
    background-image: themed("form-delete-icon-image-url");
  }

}
