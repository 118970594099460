@import './_page-title.scss';
@import './_search-bar.scss';

/* List */

$full-list-height: 100%;
$page-title-list-height: calc(100% - #{$page-title-height});
$search-bar-list-height: calc(100% - #{$search-bar-full-height});
$page-title-search-bar-list-height: calc(100% - #{$page-title-height} - #{$search-bar-full-height});

.additional-selection {
  margin-left: 10px;

  .additional-selection-icon {
    font-size: 19px;
  }
}