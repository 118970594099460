@mixin tn-button-variant($bg-color) {
  @if $bg-color {
    color: #ffffff;
    background-color: $bg-color;
    border-color: darken($bg-color, 5%);

    &:hover, &:focus, &:active {
      color: #ffffff;
      background-color: darken($bg-color, 12%);
    }
  }
}

.tn-bottom-button-wrapper {
  width: 100%;
  height: 60px;

  display: flex;

  color: white;

  .action-button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 50%;
    margin: 5px;

    &:first-child {
      margin-left: 10px;
    }

    &:last-child {
      margin-right: 10px;
    }
  }

  .cancel {
    background-color: #607089;
  }

  .confirm {
    background-color: #1ab61a;

    &.unclickable {
      background-color: rgba(26, 182, 26, 0.5);
    }
  }
}