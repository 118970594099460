// OIDS Style
// Created by Shan - 2019-10-23
@import "../../scss/base/base";
@import "../../scss/layouts/layout";

$oids-override: (
  "base-bg-color": #ffffff,
  "border-color-primary": #082c4e,
  // login page
  "login-logo-image-url": url("../../../assets/images/clients/oids/login_logo.png"),
  "login-logo-height": 120px,
  "login-logo-width": 435px,

  // nav logo
  "nav-logo-image-url": url("../../../assets/images/clients/oids/login_logo.png"),
  "nav-logo-height": 50px,
  "nav-logo-width": 215px,

  // empty page logo
  "empty-page-logo-image-url": url("../../../assets/images/clients/oids/empty_page_logo.png"),
  "empty-page-logo-height": 78px,
  "empty-page-logo-width": 325px,
  
);

.theme-oids {
  font-size: 18px !important;

  // medium size
  .side-nav-tooltip {
    display: block;
  }

  .tn-sidebar {
    min-width: $layout-collapsed-sidebar-fixed-width !important;
    width: $layout-collapsed-sidebar-fixed-width !important;

    .label-title {
      display: none !important;
    }
    .nav-item-wrapper {
      display: flex !important;
      justify-content: center !important;
      .nav-item {
        position: relative !important;

        .nav-count {
          position: absolute;
          height: 15px !important;
          width: 15px !important;
          font-size: 9px;
          left: 13px;
          top: 13px;
        }
      }
    }
  }

  .tn-content-container {
    width: $layout-collapsed-content-full-width !important;
  }

  .chat-room-container .chat-room-footer .input-area textarea {
    height: 200px !important;
  }

  .attachment-message .image-preview,
  .attachment-message .video-message .video-play-icon {
    width: 400px !important;
    height: 300px !important;

    @media only screen and (max-width: $layout-breakpoint-medium) {
      width: 250px !important;
      height: 200px !important;
    }

    @media only screen and (max-width: $layout-breakpoint-small) {
      width: 200px !important;
      height: 150px !important;
    }
  }

  .info-row {
    &.new-msg-indicator {
      background-color: red;
      margin: 0 -5px;
    }
  }

  .message-send-bar .send-button {
    min-width: 100px !important;
  }
}