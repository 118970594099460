@import "../base/_base.scss";

/* Avatar */

$avatar-normal-size: 60px;
$avatar-addon-size: 24px;

$avatar-header-size: 40px;

$avatar-profile-size: 140px;

$avatar-news-size: 50px;

$avatar-message-sender-size: 30px;

$avatar-contact-card-size: 100px;
$avatar-contact-card-addon-size: 40px;

$default-contact-avatar: '../../../assets/images/general/default_avatar.png';
$default-group-avatar: '../../../assets/images/general/default_group.png';

.default-contact-avatar {
  background-image: url($default-contact-avatar);
}

.default-group-avatar {
  background-image: url($default-group-avatar);
}

$contact-device-icon-image-url: '../../../assets/images/general/icon_device_count.png';
$contact-device-icon-bg: rgb(132, 150, 179);
$contact-device-icon-border: 1px solid #ffffff;


@mixin general-avatar($size) {
  width: $size;
  height: $size;
  border-radius: 50%;
  border: 1px solid $border-color;

  background-size: cover;
  background-position: center center;
}

@mixin avatar-extra-circle($size) {
  position: absolute;
  height: $size;
  width: $size;
  margin: 5px;
  border-radius: 50%;
  
  bottom: -10px;
  right: -10px;
}

@mixin device-icon($size) {
  @include avatar-extra-circle($size);

  background-image: url($contact-device-icon-image-url);
  background-color: $contact-device-icon-bg;
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  border: $contact-device-icon-border;

}

@mixin unread-count-layout($size) {
  @include avatar-extra-circle($size);

  font-size: 80%;
  
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 2;
}